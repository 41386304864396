import React, {useState} from 'react';
import './Palette.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../../components/Alert/Alert';

library.add(faCopy);

function Palette(props) {

    const [color, setColor] = useState('#A200FF');

    function GetTextColor(hexcolor){
        var r = parseInt(hexcolor.substring(1,3),16);
        var g = parseInt(hexcolor.substring(3,5),16);
        var b = parseInt(hexcolor.substring(5,7),16);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    return(
        
        <>
        
        <Alert color={color}/>

        <section className='palette-section reveal' key={props.index}>


            <h2 className='palette-title'>{props.mode.replace('-', ' ')}</h2>
            <div className='palette-container'>

            {
                
                props.colors.map(color => (

                    <div className='color-container'>
                        <div className="color-holder" style={{backgroundColor: color.hex.value}}>
                            <FontAwesomeIcon className='copy-icon' icon="copy" style={{color: GetTextColor(color.hex.value)}} onClick={() => {navigator.clipboard.writeText(color.hex.value); setColor(color.hex.value);}}/>
                        </div>
                        <p className='color-text'>{color.hex.value}</p>
                    </div>
                    
                ))
            }

            </div>

            <div className='underline'></div>

        </section>

        </>

    )
}

export default Palette;