import React, {useState, useEffect } from 'react';
import './Alert.css';

function Alert(props) {

    const [alert, setAlert] = useState('copy-alert');

    useEffect (() => {

        setAlert('copy-alert-active');
        setTimeout(() => { setAlert('copy-alert') }, 5000)

    }, [props.color]);

    useEffect (() => {

        setAlert('copy-alert');

    }, []);

    return(

        <div style={{backgroundColor: props.color}} className={alert}>{props.color} copied to clipboard</div>
    );

}

export default Alert;