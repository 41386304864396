import React, { useEffect, useState, useRef } from "react";
import './App.css';
import Palette from "./components/Palette/Palette";

function App() {

  const [color, setColor] = useState('#A200FF');
  const [query, setQuery] = useState('A200FF');
  const [palettes, setPalettes] = useState(null);

  const firstSection = useRef(null);

  useEffect(() => {

    async function GetPalettes() {

      const modes = ['monochrome', 'monochrome-dark', 'monochrome-light', 'analogic', 'complement', 'analogic-complement', 'triad', 'quad'];
      const requests = modes.map(mode => fetch(`https://www.thecolorapi.com/scheme?hex=${query}&format=json&mode=${mode}&count=6`));
      const responses = await Promise.all(requests);
      const data = await Promise.all(responses.map(response => response.json()));
      setPalettes(data);
    }

    GetPalettes();

  }, [query]);

  function GetTextColor(hexcolor) {
    var r = parseInt(hexcolor.substring(1, 3), 16);
    var g = parseInt(hexcolor.substring(3, 5), 16);
    var b = parseInt(hexcolor.substring(5, 7), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  window.onscroll = () => {

    let reveals = document.getElementsByClassName("reveal");

    for (const reveal of reveals) {

      let windowHeight = window.innerHeight;
      let revealTop = reveal.getBoundingClientRect().top;
      let revealPoint = 150;

      if (revealTop < windowHeight - revealPoint)
        reveal.classList.add("reveal-active");
      else
        reveal.classList.remove("reveal-active");
    }
  };

  

  return (

    <>

      <section className="first-section">

        <div>
          <h1 className="page-title">JUST ENTER A COLOR!</h1>

          <div className="form-container">
            <div className="color-input-container">
              <label htmlFor="color-input" style={{ color: GetTextColor(color) }}>{color}</label>
              <input type="color" name="" id="color-input" value={color} onChange={e => setColor(e.target.value)} />
            </div>

            <button className="generate-button" onClick={() => { setQuery(color.substring(1)); setTimeout(() => {firstSection.current.scrollIntoView({ behavior: 'smooth' });}, 100); }}>GENERATE</button>
          </div>

        </div>

      </section>

      {palettes ? (

          <section ref={firstSection}>

            {palettes.map((palette, index) => (

              <Palette colors={palette.colors} mode={palette.mode} index={index} key={index}/>
            ))}

          </section>
        ) : (<></>)}

    </>


  );
}

export default App;